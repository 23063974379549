import querystring from "querystring";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { actions, getters } from "./../store/store";
import determineAccessToServices from "./../util/DetermineAccesstoServices";

/**
 *  OAuth2 Authorization Code Grants with PKCE
 */
export default class CodeGrant {
  private OAUTH_AUTHORIZE_URL: string;
  private SERVER_TOKEN_URI: string;
  private CLIENT_REDIRECT_URI: string;
  private CLIENT_ID: string;
  private LOGOUT_URL: string;
  private store: any;
  private router: any;

  constructor(store: any, router: any) {
    this.OAUTH_AUTHORIZE_URL = process.env.VUE_APP_OAUTH_AUTHORIZE_URL || "";
    this.SERVER_TOKEN_URI = process.env.VUE_APP_OAUTH_SERVER_TOKEN || "";
    this.CLIENT_REDIRECT_URI = process.env.VUE_APP_REDIRECT_URI || "";
    this.CLIENT_ID = process.env.VUE_APP_CLIENT_ID || "";
    this.LOGOUT_URL = process.env.VUE_APP_LOGOUT_URL || "";
    this.store = store;
    this.router = router;
  }

  public async authorize(): Promise<any> {
    // (1) Get Auth Code (Can only be used once. Handled on the back-end)
    const authorizationCode = this.getParameterFromAppUrl("code");
    if (authorizationCode.length === 0) {
      // If the access code is undefined re-direct for a new one
      await this.redirectForAuthorizationCode();
    }

    // (2) Get Access token
    if (authorizationCode.length > 0 || (window.localStorage.getItem("accessToken") as any)) {
      try {
        if (authorizationCode) {
          const token = (await this.getAccessToken(authorizationCode)).data;
          const role = this.determineRoles(jwt_decode(token.access_token) ? jwt_decode(token.access_token).scopes : null);
          actions.setUserRole(this.store, role);
          actions.setUserToken(this.store, token.access_token);
          actions.setRefreshToken(this.store, token.refresh_token);
          actions.setTokenExpiration(this.store, token.expires_in);
          actions.setUserID(this.store, jwt_decode(token.access_token) ? jwt_decode(token.access_token).sub : null);
          // Determine redirect route
          this.determineRedirect(role);
        }
      } catch (e) {
        throw new Error(e);
      }
    }

    return;
  }

  public async determineRedirect(role: any): Promise<void> {
    if (role === "user" || role === "both") {
      await actions.fetchUserProfile(this.store, getters.getUserID(this.store));
      await actions.fetchHomes(this.store, getters.getUserID(this.store));
      await actions.sentrySetUser(this.store);
      // If there are multiple homes make the user select one otherwise send them to their documents
      const homes = getters.getHomes(this.store);
      // If only one home set selected home
      if (homes.length === 1) {
        const onlyHome: any = homes[0];
        const jobInfo = await actions.fetchJobInformation(this.store, onlyHome.job_number);
        const siteInfo = await actions.fetchSiteInformation(this.store, jobInfo.jobInformation);
        // If there is no statusCode the request was successful
        if (!jobInfo.statusCode) {
          // Determine Access to services
          await actions.setSelectedHome(this.store, jobInfo.jobInformation);
          await actions.setServicesAccess(this.store, determineAccessToServices(this.store));
          this.router.push("/documents");
        }
      } else {
        this.router.push("/dashboard");
      }
    } else if (role === "admin") {
      await actions.fetchUserProfile(this.store, getters.getUserID(this.store));
      await actions.sentrySetUser(this.store);
      this.router.push("/admin");
    } else {
      this.router.push("/forbidden");
    }
  }

  public async logOutOauth(): Promise<any> {
    try {
      await axios({
        method: "POST",
        url: this.LOGOUT_URL,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
        },
        withCredentials: true,
      });
      window.localStorage.setItem("accessToken", "");
      window.localStorage.setItem("role", "");
      window.localStorage.setItem("multipleHomes", "");
      window.localStorage.setItem("homes", "");
      window.localStorage.setItem("selectedHome", "");
      window.localStorage.setItem("jobInfo", "");
      window.localStorage.setItem("servicesAccess", "");
      this.router.push("/");
    } catch (e) {
      throw new Error(e);
    }
  }

  public async getTokenFromRefresh(): Promise<any> {
    try {
      const refreshToken: any = window.localStorage.getItem("refreshToken");

      if (refreshToken) {
        const token = (await this.refreshAccessToken(refreshToken)).data;
        actions.setUserRole(this.store, this.determineRoles(jwt_decode(token.access_token) ? jwt_decode(token.access_token).scopes : null));
        actions.setUserToken(this.store, token.access_token);
        actions.setRefreshToken(this.store, token.refresh_token);
        actions.setTokenExpiration(this.store, token.expires_in);
        actions.setUserID(this.store, jwt_decode(token.access_token) ? jwt_decode(token.access_token).sub : null);
      } else {
        await actions.setShowAlert(this.store, true);
      }
    } catch (e) {
      await actions.setShowAlert(this.store, true);
    }
  }

  public async startTokenRefreshInterval(): Promise<any> {
    try {
      const expiration: any = (window.localStorage.getItem("tokenExpiration") as any) - 15;

      setInterval(async (_) => {
        await this.getTokenFromRefresh();
      }, expiration * 1000);
    } catch (e) {
      await actions.setShowAlert(this.store, true);
    }
  }

  public getParameterFromAppUrl(parameter: string) {
    const sourceUrl = window.location.href;
    const params: any = {};
    params[parameter] = "";

    if (sourceUrl.search(parameter) !== -1) {
      const url = sourceUrl.replace("#", "?");
      const regex = /[?&]([^=#]+)=([^&#]*)/g;
      let match;
      do {
        match = regex.exec(url);

        if (match !== null) {
          params[match[1]] = match[2];
        }
      } while (match !== null);
    }

    return params[parameter];
  }

  public doesUserHavePermissionTo(option: string) {
    try {
      return this.determineAccess(window.localStorage.getItem("role")).includes(option);
    } catch (e) {
      console.error(e);
    }
  }

  public async refreshAccessToken(refreshToken: string): Promise<any> {
    const axiosParams: any = {
      url: this.SERVER_TOKEN_URI,
      method: "POST",
      data: {
        refresh_token: refreshToken,
        client_id: this.CLIENT_ID,
        grant_type: "refresh_token",
      },
    };

    return axios(axiosParams);
  }

  public async getAccessToken(authorizationCode: string): Promise<any> {
    const verifier = window.localStorage.getItem("verifier");
    const axiosParams: any = {
      url: this.SERVER_TOKEN_URI,
      method: "POST",
      data: {
        code: authorizationCode,
        client_id: this.CLIENT_ID,
        grant_type: "authorization_code",
        code_verifier: verifier,
        redirect_uri: this.CLIENT_REDIRECT_URI,
      },
    };
    return axios(axiosParams);
  }

  public redirectForAuthorizationCode(): void {
    const codeVerifier = this.generateCodeVerifierString();

    window.localStorage.setItem("verifier", codeVerifier);

    const parameters = {
      client_id: this.CLIENT_ID,
      redirect_uri: this.CLIENT_REDIRECT_URI,
      response_type: "code",
      code_challenge: codeVerifier,
      code_challenge_method: "plain",
      scope: "access-manager:manage user-self:manage homeowner:read homeowner:manage AUTH:send-reset-link-email",
    };

    const url = `${this.OAUTH_AUTHORIZE_URL}?${querystring.stringify(parameters)}`;
    window.location.href = url;
  }

  private determineAccess(role: any) {
    const user = [
      "/logout",
      "/dashboard",
      "/documents",
      "/service-requests",
      "/service-requests-rebates",
      "/service-requests-services",
      "/user-profile",
      "/contact-us",
      "/closing-information",
      "/survey",
      "/closing-survey",
      "/initial-survey",
      "/final-survey",
      "/hoa-contacts",
      "/initial-rebate",
      "/final-rebate",
      "/initial-service",
      "/final-service",
      "/standard-service",
    ];
    const both = [...user, "/admin"];
    const admin = [
      "/admin",
      "/initial-survey",
      "/final-survey",
      "/closing-survey",
      "/initial-rebate",
      "/final-rebate",
      "/user-profile",
      "/logout"
    ];

    switch (role) {
      case "user":
        return user;
      case "admin":
        return admin;
      case "both":
        return both;
      default:
        return [];
    }
  }

  private determineRoles(scopes: string[]) {
    if (scopes) {
      if (scopes.includes("homeowner:read") && scopes.includes("homeowner:manage")) {
        return "both";
      } else if (scopes.includes("homeowner:manage")) {
        return "admin";
      } else if (scopes.includes("homeowner:read")) {
        return "user";
      }
    }
    return "";
  }

  // Recursive generator that produces 11 additional random chars per call
  private randomString(current: string, start: number, end: number): any {
    if (start === end) {
      return current;
    }
    return current + this.randomString(Math.random().toString(36).substring(2, 15), start + 1, end);
  }

  private generateCodeVerifierString(): string {
    return this.randomString("", 0, 11);
  }
}
